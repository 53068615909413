import React from 'react'
import Helmet from 'react-helmet';
import {metadata} from '../../../config';

const Title = props => (
    <Helmet title={`${metadata.defaultTitle} | ${props.title}`}>

    </Helmet>
)

export default Title
